import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage/UniversalImage';

import { IEvidenceProps } from './model';

import './Evidence.scss';

const Evidence: FC<IEvidenceProps> = ({
  title,
  description,
  evidenceDescription,
  image,
  alt,
  sectionBackground,
}) => {
  return (
    <section
      className={classnames('evidence', {
        [`${sectionBackground?.[0]?.properties?.colorPicker?.label}-default-bg`]: sectionBackground?.[0]
          ?.properties?.colorPicker?.label,
      })}
      data-testid="evidence"
    >
      <div className="evidence__container">
        <DangerouslySetInnerHtml className="evidence__title" html={title} />
        <DangerouslySetInnerHtml className="evidence__description" html={description} />

        <div className="evidence__image-with-text">
          <div className="evidence__image">
            <UniversalImage img={image} alt={alt} />
          </div>
          <DangerouslySetInnerHtml
            className="evidence__evidence-description"
            html={evidenceDescription}
          />
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentEvidence on IEvidence {
    title
    description
    evidenceDescription
    evidenceImage: image {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    alt
    sectionBackground {
      properties {
        colorPicker {
          label
        }
      }
    }
  }
`;
export default Evidence;
