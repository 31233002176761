import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage/UniversalImage';

import { ISurveyProps } from './model';

import './Survey.scss';

const Survey: FC<ISurveyProps> = ({
  title,
  description,
  surveyTitle,
  surveyQuestion,
  surveyResults,
}) => (
  <section className="survey" data-testid="survey">
    <div className="survey__container">
      <DangerouslySetInnerHtml className="survey__title" html={title} />
      <DangerouslySetInnerHtml className="survey__description" html={description} />

      <div className="survey__results">
        <DangerouslySetInnerHtml className="survey__results-title" html={surveyTitle} />
        {surveyResults[0].properties.surveyItems.map(
          ({ properties: { title: groupTitle, items } }) => (
            <div key={groupTitle} className="survey__results-group">
              <DangerouslySetInnerHtml className="survey__results-group-title" html={groupTitle} />
              <div className="survey__results-group-images">
                {items.map(({ properties: { image, alt } }, idx) => (
                  <div key={alt + idx} className="survey__results-group-image">
                    <UniversalImage img={image} alt={alt} />
                  </div>
                ))}
              </div>
            </div>
          )
        )}
      </div>
      <DangerouslySetInnerHtml className="survey__question" html={surveyQuestion} />
    </div>
  </section>
);

export const query = graphql`
  fragment FragmentSurvey on ISurvey {
    title
    description
    surveyTitle
    surveyQuestion
    surveyResults {
      properties {
        surveyItems {
          properties {
            title
            items {
              properties {
                alt
                image {
                  fallbackUrl
                  gatsbyImage {
                    childImageSharp {
                      fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Survey;
