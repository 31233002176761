import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import BreadCrumbs from 'components/BreadCrumbs';

import { IHCPBannerProps } from './model';

import './HCPBanner.scss';

const HCPBanner: FC<IHCPBannerProps> = ({
  title,
  image,
  mobileImage,
  alt,
  breadcrumbs,
  withBreadcrumbs,
  sectionTheme,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    function updateIsMobile() {
      setIsMobile(mediaQuery.matches);
    }
    updateIsMobile();
    mediaQuery.addListener(updateIsMobile);

    return () => mediaQuery.removeListener(updateIsMobile);
  }, []);

  return (
    <section
      className={classnames('hcp-banner', {
        [`${sectionTheme}`]: sectionTheme,
      })}
      data-testid="hcp-banner"
    >
      <div className="hcp-banner__image">
        <UniversalImage img={isMobile ? mobileImage : image} imageAlt={alt} />
      </div>
      <div className="hcp-banner__container">
        <div className="hcp-banner__content">
          {withBreadcrumbs && breadcrumbs?.length ? <BreadCrumbs data={breadcrumbs} /> : null}
          <DangerouslySetInnerHtml className="hcp-banner__title" html={title} />
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentHCPBanner on IHCPBanner {
    title
    desktopImg: image {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    mobileImg: mobileImage {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 768, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    withBreadcrumbs
    sectionTheme
  }
`;

export default HCPBanner;
