import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import NFButton from 'components/common/NFButton/NFButton';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml/DangerouslySetInnerHtml';

import { IVideoIframeSectionProps } from './model';

import './VideoIframeSection.scss';

const VideoIframeSection: FC<IVideoIframeSectionProps> = ({
  title,
  subtitle,
  description,
  videoIframe,
  readMoreButton,
  readMoreButtonAriaLabel,
  sectionTheme,
  sectionAnchor,
}) => (
  <section
    className={classnames('video-iframe', {
      [`video-iframe--${sectionTheme}`]: sectionTheme,
    })}
    data-testid="video-iframe"
    id={sectionAnchor}
  >
    <DangerouslySetInnerHtml className="video-iframe__title" html={title} />
    <DangerouslySetInnerHtml className="video-iframe__subtitle" html={subtitle} />
    <DangerouslySetInnerHtml className="video-iframe__video" html={videoIframe} />
    <DangerouslySetInnerHtml className="video-iframe__description" html={description} />
    {readMoreButton?.[0]?.url ? (
      <NFButton
        className="video-iframe__button"
        btnText={readMoreButton?.[0]?.name}
        ariaLabel={readMoreButtonAriaLabel}
        link={readMoreButton?.[0].url}
        variant="outline"
      />
    ) : null}
  </section>
);

export const query = graphql`
  fragment FragmentVideoIframe on IVideoIframe {
    title
    description
    sectionAnchor
    videoIframe
    readMoreButton {
      name
      url
    }
    readMoreButtonAriaLabel
  }
`;

export default VideoIframeSection;
