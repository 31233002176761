import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import UniversalImage from 'components/common/UniversalImage/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml/DangerouslySetInnerHtml';

import { IBiasesProps } from './model';

import './Biases.scss';

const Biases: FC<IBiasesProps> = ({ title: sectionTitle, biases, sectionBackground }) => (
  <section
    className={classnames('biases', {
      [`${sectionBackground?.[0]?.properties?.colorPicker?.label}-default-bg`]: sectionBackground?.[0]
        ?.properties?.colorPicker?.label,
    })}
    data-testid="biases"
  >
    <div className="biases__container">
      <DangerouslySetInnerHtml className="biases__title" html={sectionTitle} />
      <ul>
        {biases.map(({ properties: { title, description, image, alt } }) => (
          <li key={title} className="biases__item">
            <div className="biases__item-image">
              <UniversalImage img={image} imageAlt={alt} objectFitData={{ objectFit: 'contain' }} />
            </div>
            <div className="biases__item-text">
              <DangerouslySetInnerHtml className="biases__item-title" html={title} />
              <DangerouslySetInnerHtml className="biases__item-description" html={description} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  </section>
);

export const query = graphql`
  fragment FragmentsBiases on IBias {
    title
    biases {
      properties {
        title
        description
        image {
          ...FragmentFluidSmallImage
        }
        alt
      }
    }
    sectionBackground {
      properties {
        colorPicker {
          label
        }
      }
    }
  }
`;

export default Biases;
