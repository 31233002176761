import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import Button from 'components/common/Button';
import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { INewsProps } from './model';

import './News.scss';

const News: FC<INewsProps> = ({
  newsBlock: [
    {
      properties: { title: newsTitle, blockAnchorId: newsAnchorId, news },
    },
  ],
  assetsBlock: [
    {
      properties: { title: assetsTitle, blockAnchorId: assetsAnchorId, assets },
    },
  ],
  sectionBackground,
}) => {
  useEffect(() => {
    function handleHashChange() {
      const id = window.location.hash.substr(1);
      const scrollTarget = document.querySelector(`[data-id="${id}"]`);

      if (scrollTarget && !scrollTarget.getAttribute('id')) {
        setTimeout(() => {
          const yPos = scrollTarget.getBoundingClientRect().top + window.pageYOffset - 90;
          scrollTarget.setAttribute('id', id);
          window.scrollTo({ top: yPos, behavior: 'smooth' });
        }, 0);
      }
    }
    handleHashChange();
    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <section
      className={classnames('news', {
        [`${sectionBackground?.[0]?.properties?.colorPicker?.label}-default-bg`]: sectionBackground?.[0]
          ?.properties?.colorPicker?.label,
      })}
      data-testid="news"
    >
      <div className="news__container">
        <div className="news__news" data-id={newsAnchorId}>
          <DangerouslySetInnerHtml className="news__news-title" html={newsTitle} />
          <div className="news__items">
            {news.map(
              ({
                properties: {
                  title,
                  description,
                  image,
                  alt,
                  cta: [{ name, url, target }] = [{}],
                  ctaAriaLabel,
                },
              }) => (
                <div className="news__item" key={title}>
                  <div className="news__item-image">
                    <UniversalImage img={image} imageAlt={alt} />
                  </div>

                  <div className="news__item-content">
                    <div>
                      <DangerouslySetInnerHtml className="news__item-title" html={title} />
                      <DangerouslySetInnerHtml
                        className="news__item-description"
                        html={description}
                      />
                    </div>
                    <Button
                      link={url}
                      ariaLabel={ctaAriaLabel}
                      textAlign="center"
                      variant="base"
                      target={target}
                    >
                      {name}
                    </Button>
                  </div>
                </div>
              )
            )}
          </div>
        </div>

        <div className="news__assets-block" data-id={assetsAnchorId}>
          <DangerouslySetInnerHtml className="news__assets-title" html={assetsTitle} />
          <div className="news__assets-items">
            {assets.map(
              ({ properties: { label, image, alt, description, anchorText, icon, fileLink } }) => (
                <div key={label} className="news__assets-item">
                  <span className="news__assets-item-label">{label}</span>
                  <div className="news__assets-item-image">
                    <UniversalImage img={image} imageAlt={alt} />
                  </div>

                  <div className="news__assets-item-content">
                    <DangerouslySetInnerHtml
                      className="news__assets-item-description"
                      html={description}
                    />
                    <a
                      href={fileLink?.gatsbyDocFile?.relativePath}
                      className="news__assets-item-download-btn"
                      download
                    >
                      {anchorText}
                      <DangerouslySetInnerHtml
                        className="news__assets-item--download-icon"
                        html={icon?.svg?.content}
                      />
                    </a>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentNews on INews {
    newsBlock {
      properties {
        blockAnchorId
        title
        news {
          properties {
            title
            description
            cta {
              name
              url
              target
            }
            ctaAriaLabel
            image {
              fallbackUrl
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            alt
          }
        }
      }
    }

    assetsBlock {
      properties {
        blockAnchorId
        title
        assets {
          properties {
            label
            image {
              fallbackUrl
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 520) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            alt
            description
            anchorText
            fileLink {
              gatsbyDocFile {
                relativePath
              }
            }
            icon {
              svg {
                content
              }
            }
          }
        }
      }
    }
    sectionBackground {
      properties {
        colorPicker {
          label
        }
      }
    }
  }
`;

export default News;
