import React, { FC, useCallback, useState } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { ICaseStudyProps } from './model';

import './CaseStudy.scss';

const CaseStudy: FC<ICaseStudyProps> = ({ title, description, cases, sectionBackground }) => {
  const [activeCase, setActiveCase] = useState(0);
  const { caseImage, alt, name, age, condition, story } = cases[activeCase]?.properties || {};

  const handleSetStoryActiveClick = useCallback(
    (idx: number) => () => {
      setActiveCase(idx);
    },
    []
  );

  return (
    <section
      className={classnames('case-study', {
        [`${sectionBackground?.[0]?.properties?.colorPicker?.label}-default-bg`]: sectionBackground?.[0]
          ?.properties?.colorPicker?.label,
      })}
      data-testid="case-study"
    >
      <div className="case-study__container">
        <DangerouslySetInnerHtml className="case-study__title" html={title} />
        <DangerouslySetInnerHtml className="case-study__description" html={description} />

        <div className="case-study__cases">
          <div className="case-study__labels">
            {cases.map(({ properties: { label, ariaLabel } }, idx) => (
              <button
                key={label}
                type="button"
                aria-label={ariaLabel}
                className={classnames('case-study__button', {
                  'case-study__button--active': activeCase === idx,
                })}
                onClick={handleSetStoryActiveClick(idx)}
              >
                {label}
              </button>
            ))}
          </div>

          <div className="case-study__case-info">
            <div className="case-study__person">
              <div className="case-study__person--image">
                <UniversalImage img={caseImage} imageAlt={alt} />
              </div>
              <div className="case-study__person--info">
                <p className="case-study__person--name">{name}</p>
                <DangerouslySetInnerHtml className="case-study__person--age" html={age} />
                <DangerouslySetInnerHtml
                  className="case-study__person--condition"
                  html={condition}
                />
              </div>
            </div>
            <DangerouslySetInnerHtml className="case-study__person--story" html={story} />
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentCaseStudy on ICaseStudy {
    title
    description
    cases {
      properties {
        label
        ariaLabel
        name
        age
        condition
        story
        caseImage: image {
          ...FragmentFluidSmallImage
        }
        alt
      }
    }
    sectionBackground {
      properties {
        colorPicker {
          label
        }
      }
    }
  }
`;

export default CaseStudy;
