import React, { FC, useLayoutEffect, useState } from 'react';
import { Link } from 'gatsby';

import ControlledCarousel from 'components/ControlledCarousel';
import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import responsiveObj from './constants';

import RelatedPagesList from '../RelatedPagesList/RelatedPagesList';
import { IRelatedPageItemsProps } from '../model';

const RelatedPagesCarousel: FC<IRelatedPageItemsProps> = ({
  pages,
  bgGradientColor,
  ariaLabelPrev = '',
  ariaLabelNext = '',
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 767px)');
    setIsMobile(mediaQuery.matches);

    const updateIsMobile = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addListener(updateIsMobile);

    return () => mediaQuery.removeListener(updateIsMobile);
  }, []);

  return isMobile ? (
    <RelatedPagesList pages={pages} />
  ) : (
    <ControlledCarousel
      classes="nf-articles-carousel"
      responsiveObj={responsiveObj}
      bgGradientColor={bgGradientColor}
      carouselControls={{ ariaLabelPrev, ariaLabelNext }}
      bothSidesGradient
    >
      {pages.map(({ properties: { label, image, alt, title, description, link } }) => (
        <Link to={link?.[0].url}>
          <div className="related-pages__item related-pages__item--carousel">
            <span className="related-pages__item-label">{label}</span>
            <div className="related-pages__item-image">
              <UniversalImage img={image} imageAlt={alt} />
            </div>

            <div className="related-pages__item-text related-pages__item-text--carousel">
              <DangerouslySetInnerHtml className="related-pages__item-title" html={title} />
              <DangerouslySetInnerHtml
                className="related-pages__item-description"
                html={description}
              />
            </div>
          </div>
        </Link>
      ))}
    </ControlledCarousel>
  );
};

export default RelatedPagesCarousel;
