import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import Video from 'components/common/Video';

import { IPainVideoSection } from './model';

import './PainVideoSection.scss';

const PainVideoSection: FC<IPainVideoSection> = ({ section, videoBlock }) => {
  const sectionValue = section?.[0]?.properties;

  return (
    <div
      className="pain-video-section__container"
      style={
        sectionValue?.sectionBgColor
          ? ({
              backgroundColor: `var(--${sectionValue.sectionBgColor?.[0].properties.colorPicker?.label})`,
            } as React.CSSProperties)
          : undefined
      }
    >
      {videoBlock?.length ? (
        <ul className="pain-video-section__video-list">
          {videoBlock?.map(
            ({
              properties: {
                videoTitle,
                videoDescription,
                btnPlay,
                previewImg,
                videoLink,
                btnPlayAriaLabel,
                isVideoRounded,
                videoOverlappingLabel,
                videoBottomOverlappingTitle,
              },
            }) => (
              <li>
                {videoTitle ? (
                  <DangerouslySetInnerHtml
                    html={videoTitle}
                    className="pain-video-section__video-title"
                  />
                ) : null}
                {videoDescription ? (
                  <DangerouslySetInnerHtml
                    html={videoDescription}
                    className="pain-video-section__video-description"
                  />
                ) : null}
                <Container fluid>
                  <Video
                    btnPlay={btnPlay.svg.content}
                    btnPlayAriaLabel={btnPlayAriaLabel}
                    previewImg={previewImg?.[0]?.properties || previewImg}
                    video={videoLink}
                    isVideoRounded={isVideoRounded}
                    videoOverlappingLabel={videoOverlappingLabel?.[0]?.properties}
                    videoBottomOverlappingTitle={videoBottomOverlappingTitle}
                  />
                </Container>
              </li>
            )
          )}
        </ul>
      ) : null}

      <Container fluid className="pain-video-section__content">
        {sectionValue.sectionTitle ? (
          <DangerouslySetInnerHtml
            html={sectionValue.sectionTitle}
            className="pain-video-section__content--title"
          />
        ) : null}
        {sectionValue?.sectionSubtitle ? (
          <DangerouslySetInnerHtml
            html={sectionValue.sectionSubtitle}
            className="pain-video-section__content--subtitle"
          />
        ) : null}
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentPainVideoSection on TCampaignPlainBodyProps {
    section {
      properties {
        ...FragmentSimpleSection
      }
    }
    videoBlock {
      properties {
        ...FragmentVideoBlock
      }
    }
  }
`;

export default PainVideoSection;
