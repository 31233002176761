import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IExpertProps } from './model';

import './Expert.scss';

const Expert: FC<IExpertProps> = ({
  title,
  quote,
  description,
  image,
  alt,
  expertInfo,
  details,
  sectionBackground,
}) => {
  return (
    <section
      className={classnames('expert', {
        [`${sectionBackground?.[0]?.properties?.colorPicker?.label}-default-bg`]: sectionBackground?.[0]
          ?.properties?.colorPicker?.label,
      })}
      data-testid="expert"
    >
      <div className="expert__container">
        <DangerouslySetInnerHtml className="expert__title" html={title} />

        <div className="expert__info-container">
          <div>
            <DangerouslySetInnerHtml className="expert__quote" html={quote} />
            <DangerouslySetInnerHtml className="expert__description" html={description} />
          </div>
          <div className="expert__image">
            <UniversalImage img={image} imageAlt={alt} />
            <p className="expert__info">{expertInfo}</p>
          </div>
        </div>

        <DangerouslySetInnerHtml className="expert__details" html={details} />
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentExpert on IExpert {
    title
    quote
    description
    details
    expertImage: image {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    alt
    expertInfo
    sectionBackground {
      properties {
        colorPicker {
          label
        }
      }
    }
  }
`;
export default Expert;
