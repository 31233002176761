import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Button from 'components/common/Button';
import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IRelatedInfoProps } from './model';

import './RelatedInfo.scss';

const RelatedInfo: FC<IRelatedInfoProps> = ({ title, info }) => (
  <section className="related-info" data-testid="related-info">
    <div className="related-info__container">
      <DangerouslySetInnerHtml className="related-info__title" html={title} />

      <ul className="related-info__items">
        {info.map(({ properties: { image, alt, description, link: [{ name, url }] } }) => (
          <li key={url} className="related-info__item">
            <div className="related-info__item-image">
              <UniversalImage img={image} imageAlt={alt} />
            </div>
            <DangerouslySetInnerHtml
              className="related-info__item-description"
              html={description}
            />
            <Button
              className="related-info__item-link"
              link={url}
              ariaLabel={name}
              textAlign="center"
              variant="base"
            >
              {name}
            </Button>
          </li>
        ))}
      </ul>
    </div>
  </section>
);

export const query = graphql`
  fragment FragmentRelatedInfo on IRelatedInfo {
    title
    info {
      properties {
        image {
          fallbackUrl
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 530, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        alt
        description
        link {
          url
          name
        }
      }
    }
  }
`;

export default RelatedInfo;
