import React, { FC } from 'react';
import { Link } from 'gatsby';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IRelatedPageItemsProps } from '../model';

const RelatedPagesList: FC<IRelatedPageItemsProps> = ({ pages }) => (
  <ul className="related-pages__items">
    {pages.map(({ properties: { label, image, alt, title, description, link } }) => (
      <li key={link[0].url}>
        <Link to={link[0].url}>
          <div className="related-pages__item related-pages__item--list">
            <span className="related-pages__item-label">{label}</span>
            <div className="related-pages__item-image">
              <UniversalImage img={image} imageAlt={alt} />
            </div>

            <div className="related-pages__item-text related-pages__item-text--list">
              <DangerouslySetInnerHtml className="related-pages__item-title" html={title} />
              <DangerouslySetInnerHtml
                className="related-pages__item-description"
                html={description}
              />
            </div>
          </div>
        </Link>
      </li>
    ))}
  </ul>
);

export default RelatedPagesList;
