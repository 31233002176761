import React, { FC, useCallback, useState } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import IconCustom from 'components/common/IconCustom/IconCustom';
import UniversalImage from 'components/common/UniversalImage/UniversalImage';
import useScreenRecognition from 'hooks/useScreenRecognition/useScreenRecognition';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml/DangerouslySetInnerHtml';

import { IPrevalenceProps } from './model';

import './Prevalence.scss';

const Prevalence: FC<IPrevalenceProps> = ({
  title,
  mobileImage,
  desktopImage,
  alt,
  indicators,
  prevalences,
}) => {
  const [openedIndicator, setOpenedIndicator] = useState(new Set());

  const { isMobile } = useScreenRecognition();

  const handleToggleIndicator = useCallback(
    (index) => () => {
      setOpenedIndicator((prev) => {
        const currentOpened = new Set(prev);
        currentOpened.has(index) ? currentOpened.delete(index) : currentOpened.add(index);

        return currentOpened;
      });
    },
    []
  );

  return (
    <section className="prevalence" data-testid="prevalence">
      <div className="prevalence__container">
        <DangerouslySetInnerHtml className="prevalence__title" html={title} />

        <div className="prevalence__image-with-indicators">
          <div className="prevalence__image">
            <UniversalImage img={isMobile ? mobileImage : desktopImage} alt={alt} />
          </div>

          {indicators.map(({ properties: { indicatorTooltip, indicatorAriaLabel } }, idx) => (
            <button
              key={`${indicatorAriaLabel}-${idx}`}
              type="button"
              className={classnames(`prevalence__indicator prevalence__indicator--${idx + 1}`, {
                'prevalence__indicator--opened': openedIndicator.has(idx),
              })}
              aria-label={indicatorAriaLabel}
              onClick={handleToggleIndicator(idx)}
            >
              <IconCustom icon="close_icon-1" />
              <DangerouslySetInnerHtml
                className="prevalence__indicator-tooltip"
                html={indicatorTooltip}
              />
            </button>
          ))}
        </div>

        <ul className="prevalence__items">
          {prevalences.map(
            ({
              properties: {
                title: prevalenceTitle,
                description,
                mobileItemImage,
                desktopItemImage,
                alt: imageAlt,
              },
            }) => (
              <li key={prevalenceTitle} className="prevalence__item">
                {mobileItemImage && desktopItemImage ? (
                  <div className="prevalence__item-image">
                    <UniversalImage
                      img={isMobile ? mobileItemImage : desktopItemImage}
                      alt={imageAlt}
                    />
                  </div>
                ) : null}
                <div className="prevalence__item-text">
                  <DangerouslySetInnerHtml
                    className="prevalence__item-title"
                    html={prevalenceTitle}
                  />
                  <DangerouslySetInnerHtml
                    className="prevalence__item-description"
                    html={description}
                  />
                </div>
              </li>
            )
          )}
        </ul>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentPrevalence on IPrevalences {
    title
    mobileImage: image {
      ...FragmentFluidSmallImage
    }
    desktopImage: image {
      ...FragmentFluidMiddleImage
    }
    alt
    indicators {
      properties {
        indicatorTooltip
        indicatorAriaLabel
      }
    }
    prevalences {
      properties {
        title
        description
        mobileItemImage: image {
          ...FragmentFluidSmallImage
        }
        desktopItemImage: image {
          fallbackUrl
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 530) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        alt
      }
    }
  }
`;
export default Prevalence;
