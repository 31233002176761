import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import RelatedPagesList from './RelatedPagesList';
import RelatedPagesCarousel from './RelatedPagesCarousel';

import { IRelatedPagesProps } from './model';

import './RelatedPages.scss';

const RelatedPages: FC<IRelatedPagesProps> = ({
  pages,
  sectionBackground,
  isCarouselMode,
  ariaLabelPrev,
  ariaLabelNext,
}) => {
  const PageItemsComponent = isCarouselMode ? RelatedPagesCarousel : RelatedPagesList;

  return (
    <section
      className={classnames('related-pages', {
        [`${sectionBackground?.[0]?.properties?.colorPicker?.label}-default-bg`]: sectionBackground?.[0]
          ?.properties?.colorPicker?.label,
      })}
      data-testid="related-pages"
    >
      <div className="related-pages__container">
        <PageItemsComponent
          pages={pages}
          bgGradientColor={sectionBackground?.[0]?.properties?.colorPicker?.label}
          ariaLabelPrev={ariaLabelPrev}
          ariaLabelNext={ariaLabelNext}
        />
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentRelatedPages on IRelatedPages {
    pages {
      properties {
        label
        image {
          fallbackUrl
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        alt
        title
        description
        link {
          url
        }
      }
    }
    sectionBackground {
      properties {
        colorPicker {
          label
        }
      }
    }
    isCarouselMode
  }
`;

export default RelatedPages;
