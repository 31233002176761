import React, { FC } from 'react';
import { graphql } from 'gatsby';

import UniversalImage from 'components/common/UniversalImage/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IPartnershipsProps } from './model';

import './Partnerships.scss';

const Partnerships: FC<IPartnershipsProps> = ({ title, description, image, alt }) => (
  <section className="partnerships" data-testid="partnerships">
    <div className="partnerships__container">
      <div className="partnerships__content">
        <div className="partnerships__text">
          <DangerouslySetInnerHtml className="partnerships__title" html={title} />
          <DangerouslySetInnerHtml className="partnerships__description" html={description} />
        </div>
        <div className="partnerships__image">
          <UniversalImage img={image} imageAlt={alt} />
        </div>
      </div>
    </div>
  </section>
);

export const query = graphql`
  fragment FragmentPartnerships on IPartnerships {
    title
    description
    partnershipsImage: image {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    alt
  }
`;

export default Partnerships;
