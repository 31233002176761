const responsiveObj = {
  desktopLarge: {
    breakpoint: { max: 6000, min: 1221 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  desktop: {
    breakpoint: { max: 1220, min: 991 },
    items: 3,
    partialVisibilityGutter: -1,
  },
  tablet: {
    breakpoint: { max: 990, min: 768 },
    items: 3,
    partialVisibilityGutter: 0,
  },
};

export { responsiveObj as default };
